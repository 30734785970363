<template>
  <div class="user-playlists">
    <h2>Daftar Calon Siswa</h2>
    <div v-if="siswas">
      <ListView :siswas="siswas" />
    </div>

  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getSekolahUser from '@/composables/getSekolahUser'
import getCollection from '@/composables/getCollection'
import ListView from '@/components/ListView.vue'
import getDocument from "@/composables/getDocument";

export default {
  components: { ListView },
  setup() {



    const { user } = getUser()



      const { documents: siswas } = getCollection(
          'prestasi',
          ['sekolah', '==',user.value.displayName  ]
      )




    return { siswas }
  },

}
</script>

<style scoped>
h2 {
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--secondary)
}
.btn {
  margin-top: 20px;
}
</style>
<template xmlns="http://www.w3.org/1999/html">

  <div class="error" v-if="error">{{ error }}</div>
  <div v-if="prestasi" class="playlist-details" >

    <div class="w3-container">
      <h2>PERHATIAN VERIFIKASI</h2>
      <p>Perhatikan Sertifikat Akdemis Dan Non Akademis</p>
      <p>Sesuaiakan Pembobotan Dengan Lampiran PERATURAN BUPATI tentang PPDB</p>

    </div>

    <div class="w3-bar w3-black">
      <button class="tablink" @click="openCity('Biodata', this, 'red')">Biodata</button>
      <button class="tablink" @click="openCity('SKL', this, 'red')">SKL</button>
      <button class="tablink" @click="openCity('Akademis', this, 'red')">Akademis</button>
      <button class="tablink" @click="openCity('NonAkademis', this, 'red')">Non Akademis</button>

    </div>

    <div id="Biodata" class="tabcontent" >
      <div class="playlist-info">
        <div class="coverPhoto">
          <img :src="prestasi.coverUrl">
        </div>

        <table class="styled-table">

          <tbody>
          <tr class="active-row">
            <td>Nama</td>
            <td>{{ prestasi.nama }}</td>
          </tr>
          <tr >
            <td>NISN</td>
            <td>{{ prestasi.nisn }}</td>
          </tr>
          <tr >
            <td>Alamat</td>
            <td>{{ prestasi.alamat }}</td>
          </tr>
          <tr >
            <td>Nomor Kontak</td>
            <td>{{ prestasi.telepon }}</td>
          </tr>

          <tr >
            <td>Sekolah Pilihan</td>
            <td>{{ prestasi.sekolah }}</td>
          </tr>
          <!-- and so on... -->
          </tbody>
        </table>


      </div>


    </div>

    <div id="SKL" class="tabcontent" style="display:none">

      <div class="song-list">
        <span class="jenis">DATA SURAT KETERANGAN LULUS</span>
        <div v-if="!prestasi.nomorSkl">

        </div>
        <div v-else class="single-song">
          <div class="details">

            <table class="styled-table">

              <tbody>
              <tr class="active-row">
                <td>Nomor SKL</td>
                <td>{{ prestasi.nomorSkl}}</td>
              </tr>
              <tr >
                <td>Poto</td>
                <td><div class="cover">
                  <img :src="prestasi.sertifikat">
                </div></td>
              </tr>
              <tr >
                <td>Nilai Rata2 SKL</td>
                <td>{{ prestasi.nilaiSkl }}</td>
              </tr>
              <tr>
                <td>VERIFIKASI SEKOLAH</td>

                <td>
                  <input type="text" placeholder="Masukkan Nilai Verifikasi" required v-model="nilai">
                <p>Gunakan titik. Jangan tanda koma</p></td>
              </tr>

              <tr>
                <td>

                </td>
                <td>
                  <button  @click="handleClickSkl(prestasi.id)">SIMPAN</button>
                </td>
              </tr>

              <!-- and so on... -->
              </tbody>
            </table>

          </div>

        </div>
      </div>
    </div>

    <div id="Akademis" class="tabcontent" style="display:none">
      <h2 class="judul">JALUR PRESTASI AKADEMIS</h2>


      <div class="song-list">
        <span class="jenis">DATA KOMPETISI SAINS NASIONAL (KSN)</span>
        <div v-if="!prestasi.ksn.length"> Belum Ada.</div>
        <div v-for="dataKsn in prestasi.ksn" :key="dataKsn.id" class="single-song">
          <div class="details">


            <table class="styled-table">

              <tbody>
              <tr class="active-row">
                <td>Nomor Setifikat</td>
                <td>{{ prestasi.nomorSkl}}</td>
              </tr>
              <tr >
                <td>Tingkat Kompetisi</td>
                <td>{{ dataKsn.tingkat }}</td>
              </tr>
              <tr >
                <td>Bidang</td>
                <td>{{ dataKsn.bidang }}</td>
              </tr>
              <tr >
                <td>Sertifikat</td>
                <td><div class="cover">
                  <img :src="prestasi.sertifikat">
                </div></td>
              </tr>
              <tr >
                <td>SKOR</td>
                <td v-if="prestasi.skorAkademis">{{ prestasi.skorAkademis }}</td>
                <td v-else>BELUM DI SKOR</td>
              </tr>
              <tr>
                <td>VERIFIKASI SEKOLAH</td>

                <td><input type="text" placeholder="Masukkan Skor" required v-model="skorAkademis"></td>
              </tr>
              <tr>
                <td></td>
                <td> <button  @click="handleClick(dataKsn.id)">SIMPAN SKOR</button></td>


              </tr>

              <!-- and so on... -->
              </tbody>
            </table>

          </div>

        </div>

      </div>
    </div>


    <div id="NonAkademis" class="tabcontent" style="display:none">

      <h2 class="judul">JALUR PRESTASI NON-AKADEMIS</h2>

      <div class="song-list">
        <span class="jenis">DATA PRESTASI NON AKADEMIS</span>
        <div v-if="!prestasi.nonAkademis.length"> Belum Ada.</div>
        <div v-for="dataKsn in prestasi.nonAkademis" :key="dataKsn.id" class="single-song">
          <div class="details">

            <table class="styled-table">

              <tbody>
              <tr class="active-row">
                <td>Nomor Serfikitat</td>
                <td>{{ dataKsn.nomor}}</td>
              </tr>
              <tr >
                <td>Peringkat</td>
                <td>{{ dataKsn.peringkat }}</td>
              </tr>
              <tr >
                <td>Poto</td>
                <td><div class="cover">
                  <img :src="dataKsn.sertifikat">
                </div></td>
              </tr>
              <tr >
                <td>SKOR</td>
                <td v-if="prestasi.skorNonAkademis">{{ prestasi.skorNonAkademis }}</td>
                <td v-else>BELUM DI SKOR</td>
              </tr>
              <tr>
                <td>VERIFIKASI SEKOLAH</td>

                <td><input type="text" placeholder="Masukkan Skor" required v-model="skorNonAkademis"></td>
              </tr>
              <tr>
                <td></td>
                <td> <button  @click="handleClicknon(dataKsn.id)">SIMPAN SKOR</button></td>


              </tr>


              <!-- and so on... -->
              </tbody>
            </table>

          </div>

        </div>

      </div>
    </div>


    <!-- playlist information -->






  </div>
</template>

<script>


import TambahKsn from '@/components/TambahKsn'
import TambahSkl from '@/components/TambahSkl'
import TambahNon from "@/components/TambahNon";
import useStorage from '@/composables/useStorage'
import useDocument from '@/composables/useDocument'
import getDocument from '@/composables/getDocument'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import getUser from '@/composables/getUser'
import {computed, ref} from 'vue'
import { useRouter } from 'vue-router'

export default {
  props: ['id'],
  components: { TambahKsn, TambahSkl,TambahNon },
  setup(props) {

    const { error, document: prestasi } = getDocument('prestasi', props.id)
    const { deleteDoc, updateDoc } = useDocument('prestasi', props.id)
    const { deleteImage } = useStorage()
    const router = useRouter()
    const nilai = ref('')
    const skorAkademis = ref('')
    const skorNonAkademis = ref('')






    const handleClick = async (id) => {
      const ksn = prestasi.value.ksn.filter((dataKsn) => dataKsn.id != id)
      await updateDoc({

        skorAkademis: skorAkademis.value


      })
    }

    const handleClicknon = async (id) => {
      const nonAkademis = prestasi.value.nonAkademis.filter((non) => non.id != id)
      await updateDoc({

        skorNonAkademis: skorNonAkademis.value


      })
    }


    const handleClickSkl = async (id) => {

      await updateDoc({

            nilaiSkl: nilai.value


      })
    }

    const  openCity = (pageName, elmnt, color) => {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      // Remove the background color of all tablinks/buttons
      tablinks = document.getElementsByClassName("tablink");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].style.backgroundColor = "";
      }

      // Show the specific tab content
      document.getElementById(pageName).style.display = "block";


    }


    return { error,  handleClick, handleClickSkl, handleClicknon, prestasi, openCity,nilai,skorAkademis,skorNonAkademis}
  }
}
</script>

<style>
.playlist-details {
  display: block;
  margin: auto;

  border: 3px solid green;
  padding: 10px;


  gap: 80px;
}

.song-list {


  position: relative;
  margin: auto;
  padding: 10px;
  width: 500px;


}
.jenis {

  height: 35px;

  font-weight: bold;
  text-align: center;
  margin: auto;
  display: block;
}

.judul {


  display: block;
  margin: auto;
  width: 70%;
  border: 3px solid green;
  padding: 10px;
  margin-bottom: 30px;
  text-align: center;



}

#Biodata
{
  height: 11in;
  width: 8.5in;
}
.coverPhoto {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  margin: auto;
  padding: 10px;
  width: 500px;
}

.cover {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  padding: 160px;
}
.cover img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 200%;
  max-height: 200%;
}
.playlist-info {
  text-align: center;
}
.playlist-info h2 {
  text-transform: capitalize;
  font-size: 28px;
  margin-top: 20px;
}
.playlist-info p {
  margin-bottom: 20px;
}
.username {
  color: #999;
}
.description {
  color: black;
}
.single-song {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed var(--secondary);
  margin: auto;
}

.tablink {
  background-color: #555;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 25%;
}

.tablink:hover {
  background-color: #777;
}

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
  color: white;
  display: none;
  padding: 100px 20px;
  height: 100%;
}
.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}





.styled-table {
  border-collapse: collapse;
  margin: auto;
  margin-top: 30px;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  max-width: 800px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}


#Home {background-color: red;}
#News {background-color: green;}
#Contact {background-color: blue;}
#About {background-color: orange;}


</style>
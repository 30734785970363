<template>
  <div v-for="siswa,index in siswas" :key="siswa.id">
    <router-link :to="{ name: 'DetailSiswa', params: { id: siswa.id }}">
      <div class="single">
        <div>
          <h1>{{++index}}</h1>
        </div>
        <div class="thumbnail">
          <img :src="siswa.poto">
        </div>

        <div class="info">
          <h3>{{ siswa.nama}}</h3>
          <p>{{ siswa.nik }}</p>
          <p>{{ siswa.nisn}}</p>
          <p>{{ siswa.keterangan }}</p>

        </div>

      </div>
    </router-link>
  </div>
</template>

<script>
import getDocument from "@/composables/getDocument";

export default {
  props: ['siswas'],

}
</script>

<style scoped>
.single {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
}
.single:hover {
  box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
.thumbnail {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
  border-radius: 10px;
}
img {
  max-width: 150%;
  max-height: 150%;
  display: block;
}
.info {
  margin: 0 30px;
}
.song-number {
  margin-left: auto;
}

</style>
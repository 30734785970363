<template>
  <div class="error" v-if="error">{{ error }}</div>
  <div v-if="siswa" class="siswa-details">

    <div class="siswa-info">
      <div class="cover">
        <img :src="siswa.image">
      </div>

    </div>



    <div class="detail-siswa">
      <div v-if="!siswa">Tidak ada siswa.</div>
      <div  class="single-song">
        <div class="details">

          <table>
            <tr>
              <td>NISN</td> <td>{{ siswa.nisn }}</td>
            </tr>
            <tr>
              <td>Nama</td> <td>{{ siswa.firstName}} {{siswa.lastName}}</td>
            </tr>
            <tr>
              <td>NIK</td> <td>{{ siswa.nik }}</td>
            </tr>
            <tr>
              <td>KK</td> <td>{{ siswa.kk }}</td>
            </tr>
            <tr>
              <td>Telepon</td> <td>{{ siswa.mobile }}</td>
            </tr>
            <tr>
              <td>Jenis Kelamin</td> <td>{{ siswa.gender }}</td>
            </tr>
            <tr>
              <td>Email</td> <td>{{ siswa.email }}</td>
            </tr>



          </table>

        </div>

      </div>

      <div>
        <Status :id="user_id"  :siswa="siswa" :pilihan="pilihan" class="verifikasi"/>
      </div>

    </div>


    <div class="keterangan"><span>STATUS VERIFIKASI</span></div>

    <div class="detail-status">
      <div v-if="!status">STATUS BELUM DITENTUKAN.</div>
      <div  class="single-song" v-if="status">
        <div class="details">

          <table>
            <tr>
              <td>STATUS</td> <td>{{ status.verifikasi }}</td>
            </tr>
            <tr>
              <td>KETERANGAN</td> <td>{{ status.keterangan}} </td>
            </tr>



          </table>

        </div>

      </div>



    </div>
    <div class="keterangan"><span>SEKOLAH PILIHAN</span></div>

    <div class="detail">
      <div v-if="!pilihan">Tidak ada siswa.</div>
      <div v-if="pilihan">
        <table>
          <tr>
            <td>Alamat Manual</td> <td>{{ pilihan.alamat }}</td>
          </tr>
          <tr>
            <td>Desa</td> <td>{{ pilihan.desa }}</td>
          </tr>
          <tr>
            <td>Sekolah Pilihan</td> <td>{{ pilihan.sekolah }}</td>
          </tr>
          <tr>
            <td>Daftar</td> <td>{{ new Date(pilihan.timestamp.seconds * 1000).toLocaleDateString() }}</td>
          </tr>

        </table>


      </div>



    </div>

    <div class="keterangan"><span>KOORDINAT</span></div>

    <div class="detail">


      <div v-if="koordinat">
          <table>
          <tr>
            <td>Alamat (otomatis oleh Google)</td> <td>{{ koordinat.alamat }}</td>
          </tr>
          <tr>
            <td>Latitude</td> <td>{{ koordinat.latitude }}</td>
          </tr>
          <tr>
            <td>Longitude</td> <td>{{ koordinat.longitude }}</td>
          </tr>

        </table>

        <GoogleMap :koordinat="koordinat"/>
      </div>
      <div v-else>
        LOKASI TIDAK DITENTUKAN ?
        <GoogleMapNot :siswa="siswa" :id="user_id"  class="verifikasi"/>

      </div>







    </div>



    <div class="keterangan"><span>KARTU KELUARGA</span></div>
    <div v-if="!kk">Tidak ada Kartu Keluarga.</div>
    <div class="kk" v-if="kk">
      <img :src="kk.image">
    </div>


    <div class="keterangan"><span>SURAT KETERANGAN LULUS</span></div>
    <div v-if="!skl" >Tidak ada SKL.</div>
    <div class="kk"  v-if="skl">
      <img :src="skl.image">
    </div>



  </div>



</template>

<script>
import useStorage from '@/composables/useStorage'
import useDocument from '@/composables/useDocument'
import getDocument from '@/composables/getDocument'
import getUser from '@/composables/getUser'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import Status from '@/components/Status'
import GoogleMap from "@/components/GoogleMap";
import GoogleMapNot from "@/composables/GoogleMap";


export default {
  props: ['id'],
  components: {GoogleMapNot, GoogleMap, Status },
  setup(props) {

    const { user } = getUser()
    const { error, document: siswa } = getDocument('users', props.id)

    const { errorstatyss, document: koordinatSekolah } = getDocument('koordinat', '00KxEA8JC5SNbJ5H1lySXcHpiQu2')

    const { errorKK, document: kk } = getDocument('aktelahirs', props.id)


    const { errorSkl, document: skl } = getDocument('skls', props.id)

    const { errorPilihan, document: pilihan } = getDocument('sekolah_pilihan', props.id)

    const { errorKoordinat, document: koordinat } = getDocument('koordinat', props.id)


    const { errorstatys, document: status } = getDocument('status', props.id)








    const user_id = props.id



    return { error, siswa,kk,skl,pilihan,errorKK,errorSkl, errorPilihan, koordinat,user_id,status, koordinatSekolah}
  }

}



</script>

<style>

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.siswa-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 80px;
  width: 1080px;
}
.cover {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  padding: 160px;
  border-top: 2px dashed var(--secondary);
}

.kk {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  padding: 10px;
  width: 1920px;
  height: 1080px;
}
.cover img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 200%;
  max-height: 200%;
}


.kk img {
  display: block;
  position: absolute;
  max-width:100%;
  max-height:100%;


}
.siswa-info {
  text-align: center;
}
.siswa-info h2 {
  text-transform: capitalize;
  font-size: 28px;
  margin-top: 20px;
}
.siswa-info p {
  margin-bottom: 20px;
}
.username {
  color: #999;
}

.keterangan {
  border-bottom: 1px dashed var(--secondary);
  border-top: 1px dashed var(--secondary);
  margin-left: 10px;
}
.description {
  text-align: left;
}
.detail-siswa {
  padding: 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed var(--secondary);
  margin-bottom: 20px;
  margin-left: 50px;
  width: 700px;
}

.detail-status {

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed var(--secondary);
  margin-bottom: 20px;

  width: 700px;
  background-color: yellow;
  text-color: white;
}

.detail {

  display: block;

  align-items: left;
  border-bottom: 1px dashed var(--secondary);
  margin-bottom: 20px;
  width: 700px;

}

.verifikasi {

  display: flex;


  border-bottom: 1px dashed var(--secondary);


}
</style>
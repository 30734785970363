<template>
  <div class="add-song">
    <button v-if="!showForm" @click="showForm = true">VERIFIKASI</button>
    <form v-if="showForm" @submit.prevent="handleSubmit">
      <h4>Apakah Calon Disetuji</h4>
      <input type="radio" id="setuju" v-model="title" value="SETUJU">
      <label for="setuju">SETUJU</label><br>
      <input type="radio" id="tidak" v-model="title" value="TIDAK DISETUJUI">
      <label for="tidak">TIDAK DISETUJUI</label><br>


      <textarea  v-model="artist" id="artist" placeholder="alasan"></textarea>

      <button>TENTUKAN</button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import useDocument from '../composables/useDocument'

export default {
  props: ['id', 'siswa', 'pilihan'],
  setup(props) {
    const title = ref('')
    const artist = ref('')
    const showForm = ref(false)
    const { addDoc } = useDocument('status', props.id)

    const handleSubmit = async () => {
      const setuju = {
        user_id: props.id,
        verifikasi: title.value,
        keterangan: artist.value,
        nama: props.siswa.firstName + " " + props.siswa.lastName,
        nik: props.siswa.nik,
        nisn: props.siswa.nisn,
        poto: props.siswa.image,
        sekolah: props.pilihan.sekolah
      }
      const res = await addDoc( setuju,props.id+""
      )
      title.value = ''
      artist.value = ''
    }

    return { title, artist, showForm, handleSubmit }
  }
}
</script>

<style scoped>
.add-song {
  text-align: center;
  margin-top: 40px;
}
form {
  max-width: 100%;

}
</style>

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCB-IRzRaKxzJ0Caebvta_8NEyIXlSTYjY",
    authDomain: "ppdbdairi.firebaseapp.com",
    databaseURL: "https://ppdbdairi-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ppdbdairi",
    storageBucket: "ppdbdairi.appspot.com",
    messagingSenderId: "558310202196",
    appId: "1:558310202196:web:484dede105538b517286c1",
    measurementId: "G-PBNB533N9R"
};

firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp


export { projectFirestore, projectAuth, projectStorage, timestamp }



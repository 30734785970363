<template>
  <button v-if="!showForm" @click="showForm = true">TENTUKAN LOKASI</button>
  <div v-if="showForm">

    <GoogleMap

        api-key="AIzaSyCbkgyfbOwpJJuSEqRqJiMuho0m-BAXZI0"
        style="width: 100%; height: 500px"
        :center="center"
        :zoom="15">
      <Marker :options="markerOptions" @dragend="dragends($event)" />
    </GoogleMap>
    <button @click="simpan">TENTUKAN</button>

  </div>

</template>

<script>

import { GoogleMap, Marker } from 'vue3-google-map'
import {ref} from "vue";
import useDocument from "@/composables/useDocument";


export default
{
  props: ['id', 'siswa'],
  components: { GoogleMap, Marker },
  setup(props) {

    const center = { lat: 2.7440275512288674, lng: 98.31424752570578  }
    const markerOptions = { position: center, label: 'R', title: 'RUMAH SISWA', draggable:true }
    const lats = ref(0)
    const longs = ref(0)
    const showForm = ref(false)
    const { addDoc } = useDocument('koordinat', props.id)

    const simpan = async () => {
      const setuju = {
        user_id: props.id,
        latitude: lats.value,
        longitude: longs.value,
        koordinatCompleted: 1,
        alamat: "",
        username: props.siswa.firstName + " " + props.siswa.lastName


      }
      const res = await addDoc( setuju,props.id+""
      )

    }

    return { center, markerOptions, lats,longs, showForm,simpan }
  },
  methods: {
    dragends: function(e)
    {
      this.lats = e.latLng.lat();
      this.longs = e.latLng.lng()
      console.log(e.latLng.lat(),e.latLng.lng());

    },

  }
}
</script>

<style>
button
{
  background-color: red;
}

</style>
<template>
  <GoogleMap
      api-key="AIzaSyCbkgyfbOwpJJuSEqRqJiMuho0m-BAXZI0"
      style="width: 100%; height: 500px"
      :center="center"
      :zoom="15"


  >
    <Marker :options="markerOptions"/>
  </GoogleMap>
</template>

<script>
import { defineComponent } from 'vue'
import { GoogleMap, Marker } from 'vue3-google-map'


export default
{
  props: ['koordinat'],
  components: { GoogleMap, Marker },
  setup(props) {
    //console.log("isinya "+props.koordinat.latitude)
    const center = { lat: props.koordinat.latitude, lng: props.koordinat.longitude }
    const markerOptions = { position: center, label: 'R', title: 'RUMAH SISWA' }

    return { center, markerOptions }
  }
}
</script>
<template>
  <div class="add-song">
    <button v-if="!showForm" @click="showForm = true"> Daftarkan Prestasi Non Akademis</button>

    <form v-if="showForm" @submit.prevent="handleSubmit">
      <h4>Tambah Prestasi Non Akademis </h4>
      <p>Upload Sertifikat Peringkat I, II, dan III dalam bidang non-akademis tingkat kabupaten/provinsi/nasional.
      </p>
      <input type="text" placeholder="Nomor" required v-model="nomor">
      <input type="text" placeholder="Bidang" required v-model="bidang">
      <input type="text" placeholder="Tingkat" required v-model="tingkat">
      <label>Upload Sertifikat</label>
      <input type="file" @change="handleChange">
      <div class="error">{{ fileError }}</div>
      <button>Tentukan</button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import useDocument from '../composables/useDocument'
import useStorage from "@/composables/useStorage";

export default {
  props: ['prestasi'],
  setup(props) {
    const nomor = ref('')
    const bidang = ref('')
    const tingkat = ref('')
    const { filePath, url, uploadImage } = useStorage()
    const isPending = ref(false)
    const fileError = ref(null)
    const file = ref(null)


    const showForm = ref(false)
    const { updateDoc } = useDocument('prestasi', props.prestasi.id)

    const handleSubmit = async () => {
      if (file.value) {

        isPending.value = true
        await uploadImage(file.value, props.prestasi.id)

        const nonBaru = {
          nomor: nomor.value,
          bidang: bidang.value,
          tingkat: tingkat.value,
          sertifikat: url.value,

          id: Math.floor(Math.random() * 1000000)
        }
        const res = await updateDoc({
          nonAkademis: [...props.prestasi.nonAkademis, nonBaru]
        })
        nomor.value = ''
        bidang.value = ''
        tingkat.value = ''
        isPending.value = false
      }
    }
    // allowed file types
    const types = ['image/png', 'image/jpeg']

    const handleChange = (e) => {
      let selected = e.target.files[0]
      console.log(selected)

      if (selected && types.includes(selected.type)) {
        file.value = selected
        fileError.value = null
      } else {
        file.value = null
        fileError.value = 'Tolong untuk memilih file JPG atau PNG'
      }
    }

    return { nomor,bidang,tingkat, showForm, handleSubmit , fileError, handleChange,isPending}
  }
}
</script>

<style scoped>
.add-song {
  text-align: center;
  margin-top: 40px;
}
form {
  max-width: 100%;
  text-align: left;
}
</style>
<template>
  <div class="navbar">
    <nav>
      <img src="@/assets/ppdb_dairi.png"/>

      <div v-if="user">

     <h1><router-link :to="{ name: 'ListSiswa' }">Calon Siswa</router-link></h1>
        <!--      <h1><router-link :to="{ name: 'Diterima' }">Diterima</router-link></h1>


          //  <span>{{ user.displayName }}</span>

-->

      </div>
      <div class="links">
        <div v-if="user">



               <button @click="handleClick">Logout</button>


        </div>
        <div v-else>


         <router-link class="btn" :to="{ name: 'Login' }">Login</router-link>

        </div>
      </div>
    </nav>
  </div>
     </template>

     <script>
     // challenge
     //   - only show the logout button if we are logged in
     //   - only show the signup and login links if we are not logged in
     //   - use the getUser composable to help

     import getUser from '../composables/getUser'
     import useLogout from '../composables/useLogout'
     import { useRouter } from 'vue-router'

     export default {
       setup() {

      const { user } = getUser()
       const { logout } = useLogout()
       const router = useRouter()

      const handleClick = async () => {
        await logout()
       console.log('logged out')
         router.push({ name: 'Login' })
     }

     return { handleClick, user }
         return user
       }
     }
     </script>

     <style scoped>
       .navbar {
         padding: 16px 10px;
         margin-bottom: 60px;
         background: white;
       }
       nav {
         display: flex;
         align-items: center;
         max-width: 1200px;
         margin: 0 auto;
       }
       nav img {
         max-height: 60px;
       }
       nav h1 {
         margin-left: 20px;
       }
       nav .links {
         margin-left: auto;
       }
       nav .links a, button {
         margin-left: 16px;
         font-size: 14px;
       }
       span {
         font-size: 14px;
         display: inline-block;
         margin-left: 16px;
         padding-left: 16px;
         border-left: 1px solid #eee;
       }
     </style>
<template>
  <div class="add-song">
    <button v-if="!showForm" @click="showForm = true"> Daftarkan SuratKeterangan Lulus</button>
    <form v-if="showForm" @submit.prevent="handleSubmit">
      <h4>Isi SKL </h4>
      <input type="text" placeholder="Nomor" required v-model="nomor">
      <p>PASTIKAN NILAI RATA RATA DIISI DENGAN BENAR</p>
      <input type="text" placeholder="Nilai Rata-Rata" required v-model="nilai">
      <label>Upload SKL</label>
      <input type="file" @change="handleChange">
      <div class="error">{{ fileError }}</div>

      <button>Tentukan</button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import useDocument from '../composables/useDocument'
import useStorage from "@/composables/useStorage";

export default {
  props: ['prestasi'],
  setup(props) {
    const nomor = ref('')
    const nilai = ref(0)
    const { filePath, url, uploadImage } = useStorage()
    const isPending = ref(false)
    const fileError = ref(null)
    const file = ref(null)



    const showForm = ref(false)
    const { updateDoc } = useDocument('prestasi', props.prestasi.id)

    const handleSubmit = async () => {
      if (file.value) {

        isPending.value = true
        await uploadImage(file.value, props.prestasi.id)


        const res = await updateDoc({
          nomorSkl: nomor.value,
          nilaiSkl: nilai.value,
          sertifikat: url.value,

        })
        nomor.value = ''
        nilai.value = 0
        isPending.value = false

      }
    }
    // allowed file types
    const types = ['image/png', 'image/jpeg']

    const handleChange = (e) => {
      let selected = e.target.files[0]
      console.log(selected)

      if (selected && types.includes(selected.type)) {
        file.value = selected
        fileError.value = null
      } else {
        file.value = null
        fileError.value = 'Tolong untuk memilih file JPG atau PNG'
      }
    }

    return { nomor, nilai, showForm, handleSubmit, fileError, handleChange,isPending }
  }
}
</script>

<style scoped>
.add-song {
  text-align: center;
  margin-top: 40px;
}
form {
  max-width: 100%;
  text-align: left;
}
</style>
import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const useDocument = (collection, id) => {

  let error = ref(null)
  let isPending = ref(false)
  let docRef = projectFirestore.collection(collection).doc(id)



  const addDoc = async (doc,sid) => {
    error.value = null
    isPending.value = true

    try {
      const res = await projectFirestore.collection(collection).doc(sid).set(doc)
      isPending.value = false
      return res
    }
    catch(err) {
      console.log(err.message)
      error.value = 'tidak dapat meneyetuji'
      isPending.value = false
    }



  }
  const updateDoc = async (updates) => {
    isPending.value = true
    error.value = null

    try {
      const res = await docRef.update(updates)
      isPending.value = false
      return res
    }
    catch(err) {
      console.log(err.message)
      isPending.value = false
      error.value = 'could not update the document'
    }
  }


  return { error, isPending, addDoc, updateDoc }

}

export default useDocument
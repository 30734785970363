import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import Signup from '../views/auth/Signup.vue'
import ListSiswa from "@/views/Siswa/ListSiswa";
import SiswaDetail from "@/views/Siswa/SiswaDetail";
import Diterima from "@/views/Siswa/Diterima";
import TambahSiswa from "@/views/Siswa/TambahSiswa";

import { projectAuth } from '../firebase/config';
import TambahSIswa from "@/views/Siswa/TambahSiswa";
import DetailPrestasi from "@/views/Siswa/DetailPrestasi";

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (!user) {
    next({ name: 'Login' })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,

  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,

  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: requireAuth
  },
  {
    path: '/daftar',
    name: 'TambahSiswa',
    component: TambahSiswa
  },
  {
    path: '/detailPrestasi/:id',
    name: 'DetailPrestasi',
    props: true,
    component: DetailPrestasi
  },
  {
    path: '/sekolah/',
    name: 'ListSiswa',
    component: ListSiswa,
    beforeEnter: requireAuth
  },
  {
    path: '/diterima/',
    name: 'Diterima',
    component: Diterima,
    beforeEnter: requireAuth
  },
  {
    path: '/siswa/:id',
    name: 'DetailSiswa',
    props: true,
    component: SiswaDetail,
    beforeEnter: requireAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

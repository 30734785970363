<template>
  <div class="home">
   <span class="judul">JALUR PRESTASI DAN TAHAP 2 DITUTUP</span>

  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',

}
</script>
<stye>
.judul
{
  text-align: center;
  margin: auto;

}
.home
{
text-align: center;
border: 3px solid green;
}


</stye>

<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <h4>BIODATA SISWA</h4>
      <input type="text" required placeholder="Nama Lengkap Siswa" v-model="nama">
      <input type="text" required placeholder="NISN" v-model="nisn">
      <input type="text" required placeholder="Nomor Kontak" v-model="telepon">
      <textarea required placeholder="Alamat" v-model="alamat"></textarea>

        <p>Pilih Sekolah</p>
        <select v-model="sekolah">
          <option disabled value="">Pilih Sekolah Tujuan</option>
          <option value="SMP NEGERI 1 SIDIKALANG">SMP NEGERI 1 SIDIKALANG</option>
          <option value="SMP NEGERI 2 SIDIKALANG">SMP NEGERI 2 SIDIKALANG</option>
          <option value="SMP NEGERI 3 SIDIKALANG">SMP NEGERI 3 SIDIKALANG</option>
          <option value="SMP NEGERI 1 BERAMPU">SMP NEGERI 1 BERAMPU</option>
          <option value="SMP NEGERI 1 GUNUNG SITEMBER">SMP NEGERI 1 GUNUNG SITEMBER</option>
          <option value="SMP NEGERI 1 LAE PARIRA">SMP NEGERI 1 LAE PARIRA</option>
          <option value="SMP NEGERI 1 PARBULUAN">SMP NEGERI 1 PARBULUAN</option>
          <option value="SMP NEGERI 2 PARBULUAN">SMP NEGERI 2 PARBULUAN</option>
          <option value="SMP NEGERI 3 PARBULUAN">SMP NEGERI 3 PARBULUAN</option>
          <option value="SMP NEGERI 1 PEGAGAN HILIR">SMP NEGERI 1 PEGAGAN HILIR</option>
          <option value="SMP NEGERI 3 PEGAGAN HILIR">SMP NEGERI 3 PEGAGAN HILIR</option>
          <option value="SMP NEGERI 1 SIEMPAT NEMPU">SMP NEGERI 1 SIEMPAT NEMPU</option>
          <option value="SMP NEGERI 2 SIEMPAT NEMPU">SMP NEGERI 2 SIEMPAT NEMPU</option>
          <option value="SMP NEGERI 3 SIEMPAT NEMPU">SMP NEGERI 3 SIEMPAT NEMPU</option>
          <option value="SMP NEGERI 1 SIEMPATNEMPU HILIR">SMP NEGERI 1 SIEMPATNEMPU HILIR</option>
          <option value="SMP NEGERI 2 SIEMPATNEMPU HILIR">SMP NEGERI 2 SIEMPATNEMPU HILIR</option>
          <option value="SMP NEGERI 3 SIEMPATNEMPU HILIR">SMP NEGERI 3 SIEMPATNEMPU HILIR</option>
          <option value="SMP NEGERI 1 SIEMPATNEMPU HULU">SMP NEGERI 1 SIEMPATNEMPU HULU</option>
          <option value="SMP NEGERI 3 SIEMPATNEMPU HULU">SMP NEGERI 3 SIEMPATNEMPU HULU</option>
          <option value="SMP NEGERI 1 SILIMA PUNGGA PUNGGA">SMP NEGERI 1 SILIMA PUNGGA PUNGGA</option>
          <option value="SMP NEGERI 2 SILIMA PUNGGA PUNGGA">SMP NEGERI 2 SILIMA PUNGGA PUNGGA</option>
          <option value="SMP NEGERI 1 SITINJO">SMP NEGERI 1 SITINJO</option>
          <option value="SMP NEGERI 2 SUMBUL">SMP NEGERI 2 SUMBUL</option>
          <option value="SMP NEGERI 3 SUMBUL">SMP NEGERI 3 SUMBUL</option>
          <option value="SMP NEGERI 4 SUMBUL">SMP NEGERI 4 SUMBUL</option>
          <option value="SMP NEGERI 5 SUMBUL">SMP NEGERI 5 SUMBUL</option>
          <option value="SMP NEGERI 1 TANAH PINEM">SMP NEGERI 1 TANAH PINEM</option>
          <option value="SMP NEGERI 2 TANAH PINEM">SMP NEGERI 2 TANAH PINEM</option>
          <option value="SMP NEGERI 3 TANAH PINEM">SMP NEGERI 3 TANAH PINEM</option>
          <option value="SMP NEGERI 4 TANAH PINEM">SMP NEGERI 4 TANAH PINEM</option>
          <option value="SMP NEGERI 1 TIGALINGGA">SMP NEGERI 1 TIGALINGGA</option>
          <option value="SMP NEGERI 2 TIGALINGGA">SMP NEGERI 2 TIGALINGGA</option>
          <option value="SMP NEGERI 3 TIGALINGGA">SMP NEGERI 3 TIGALINGGA</option>
         
        </select>



      <!-- upload playlist image -->
      <label>Upload Pas Photo</label>
      <input type="file" @change="handleChange">
      <div class="error">{{ fileError }}</div>

      <button v-if="!isPending">Simpan</button>
      <button v-else disabled>Menyimpan...</button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { useRouter } from 'vue-router'
import DatePicker from '@/components/DatePicker'
import GoogleMapNot from "@/composables/GoogleMap";
import GoogleMap from "@/components/GoogleMap";
import Status from "@/components/Status";

export default {
  components: {DatePicker },
  setup() {
    const { filePath, url, uploadImage } = useStorage()
    const { error, addDoc } = useCollection('prestasi')

    const router = useRouter()

    const nama = ref('')
    const nisn = ref('')
    const telepon = ref('')

    const alamat = ref('')
    const sekolah = ref('')
    const file = ref(null)
    const fileError = ref(null)
    const isPending = ref(false)

    const handleSubmit = async () => {
      if (file.value) {
        isPending.value = true
        await uploadImage(file.value,nisn.value)
        const res = await addDoc({
          nama: nama.value,
          nisn: nisn.value,
          telepon: telepon.value,
          alamat: alamat.value,
          coverUrl: url.value,
          sekolah: sekolah.value,
          filePath: filePath.value, // so we can delete it later
          ksn: [],
          nonAkademis:[],
          createdAt: timestamp()
        })
        isPending.value = false
        if (!error.value) {
          router.push({ name: 'DetailPrestasi', params: { id: res.id }})
        }
      }
    }

    // allowed file types
    const types = ['image/png', 'image/jpeg']

    const handleChange = (e) => {
      let selected = e.target.files[0]
      console.log(selected)

      if (selected && types.includes(selected.type)) {
        file.value = selected
        fileError.value = null
      } else {
        file.value = null
        fileError.value = 'Tolong untuk memilih file JPG atau PNG'
      }
    }

    return { nama, nisn,telepon,alamat,sekolah, handleSubmit, fileError, handleChange, isPending }
  }
}
</script>

<style>
form {
  background: white;
}
input[type="file"] {
  border: 0;
  padding: 0;
}
label {
  font-size: 12px;
  display: block;
  margin-top: 30px;
}
button {
  margin-top: 20px;
}
</style>